import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgxUiLoaderModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import {
  ToastrModule,
  ToastNoAnimation,
  ToastNoAnimationModule,
} from 'ngx-toastr';

import { LayoutComponent } from './layout/layout.component';
import { AuthInterCeptor } from './services/authInterceptor.service';

@NgModule({
  declarations: [LayoutComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    ToastNoAnimationModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
    }),
    NgxUiLoaderModule, // import NgxUiLoaderModule
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true,
      exclude: [
        'https://api.romtix.com/api/quickSearchPatient',
        'https://api.romtix.com/api/markNotificationRead',
        'https://api.romtix.com/api/listUserNotifications',
        'https://api.romtix.com/api/getChatUsers',
        'https://api.romtix.com/api/getChatHiddenUsers',
        'https://api.romtix.com/api/getUserChats',
        'https://api.romtix.com/api/sendChatMessage',
        'https://api.romtix.com/api/sendMeetingMessage',
        'https://api.romtix.com/api/sendMessage',
        'https://api.romtix.com/api/getUserRooms',
        'https://api.romtix.com/api/getMessages',
        'https://api.romtix.com/api/getMeetingMessages',
        'https://api.romtix.com/api/downloadBackup',
        'https://api.romtix.com/api/listBackups',
      ],
    }),
  ],
  exports: [LayoutComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterCeptor,
      multi: true,
    },
  ],
})
export class CoreModule {}
